import React, { useEffect } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"
import {
  Segment,
  Form,
  Button,
  Header,
  Grid,
  Item,
  Icon,
  Flag,
} from "semantic-ui-react"
const endpoints = {
  contact: "/.netlify/functions/sendEmail",
}
const axios = require("axios")

const Contact = ({ intl }) => {
  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    register({ name: "Subject" }, { required: true })
    register({ name: "Name" }, { required: true })
    register({ name: "Email" }, { required: true })
    register({ name: "Message" }, { required: true })
  }, [])

  const sendMail = data => {
    axios.post(endpoints.contact, JSON.stringify(data)).then(response => {
      if (response.status !== 200) {
        setValue("Subject", "")
        setValue("Name", "")
        setValue("Email", "")
        setValue("Message", "")
      } else {
        console.error(response)
      }
    })
  }

  return (
    <Segment padded="very" textAlign="center">
      <Grid stackable relaxed>
        <Grid.Row textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ padding: "1em" }}>
            <Header
              as="h1"
              style={{ padding: "0.4em" }}
              textAlign="center"
              className="title"
            >
              {intl.formatMessage({ id: "contact_form.title" })}
            </Header>
            <Header
              as="h2"
              style={{ fontFamily: "Glacial" }}
              content={intl.formatMessage({ id: "contact_form.sub_title" })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} textAlign="center">
          <Grid.Column>
            <Item.Group>
              <Item>
                <Item.Content>
                  <Item.Header>
                    <Icon name="phone" /> +57 - 3137664880
                  </Item.Header>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content>
                  <Item.Header>
                    <Icon name="desktop" /> www.quanticcoffee.com
                  </Item.Header>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content>
                  <Item.Header>
                    <Icon name="inbox" /> cafequanticoficial@gmail.com
                  </Item.Header>
                </Item.Content>
              </Item>
            </Item.Group>
            <Item>
              <Item.Header>Chinchiná - Caldas</Item.Header>
              <Item.Content>
                Colombia <Flag name="colombia" />
              </Item.Content>
            </Item>
          </Grid.Column>
          <Grid.Column>
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(sendMail)}
            >
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label={intl.formatMessage({ id: "contact_form.subject_label" })}
                  placeholder={intl.formatMessage({ id: "contact_form.subject_placeholder" })}
                  name="Subject"
                  onChange={(e, { name, value }) => setValue(name, value)}
                />
                <Form.Input
                  required
                  label={intl.formatMessage({ id: "contact_form.name_label" })}
                  placeholder={intl.formatMessage({ id: "contact_form.name_placeholder" })}
                  name="Name"
                  onChange={(e, { name, value }) => setValue(name, value)}
                />
                <Form.Input
                  required
                  label="Email"
                  placeholder="some@test.com"
                  name="Email"
                  type="email"
                  onChange={(e, { name, value }) => setValue(name, value)}
                />
              </Form.Group>

              <Form.TextArea
                required
                label={intl.formatMessage({ id: "contact_form.message_label" })}
                placeholder={intl.formatMessage({ id: "contact_form.message_placeholder" })}
                name="Message"
                onChange={(e, { name, value }) => setValue(name, value)}
              />
              <Button
                content={intl.formatMessage({ id: "contact_form.boton" })}
                primary
                style={{ backgroundColor: "#72150D" }}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}
export default injectIntl(Contact)
